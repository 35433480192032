var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"bg-white p-3 rounded shadow-sm"},[_c('h1',[_vm._v("Pelacakan Darah")]),_c('b-form-group',{staticClass:"mt-5 w-100",attrs:{"label":"Masukkan Barcode","label-for":"barcode-input"}},[_c('b-form-input',{attrs:{"id":"barcode-input","type":"text","placeholder":"Masukkan barcode"},on:{"input":_vm.checkingBarcodes},model:{value:(_vm.formData.barcode),callback:function ($$v) {_vm.$set(_vm.formData, "barcode", $$v)},expression:"formData.barcode"}})],1),_c('b-row',{staticClass:"d-flex justify-content-center"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"table-wrapper w-100 mx-auto overflow-scroll"},[_c('b-table',{staticClass:"table-borderless table-responsive w-100",attrs:{"fields":_vm.table.fields,"items":_vm.table.items,"busy":_vm.table.isBusy,"show-empty":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"hp-p1-body text-center my-2"},[_c('b-spinner',{staticClass:"align-middle mr-8"}),_c('strong',[_vm._v("Memuat...")])],1)]},proxy:true}])})],1)])],1)],1),_vm._v(" "+_vm._s(_vm.errorMessages)+" "),_c('section',{staticClass:"bg-white p-3 rounded shadow-sm mt-5"},[_c('p',[_vm._v("Nama Pendonor: "+_vm._s(_vm.bloodTrackingData.patientIdentity.name))]),_c('p',[_vm._v("Nama MU: "+_vm._s(_vm.bloodTrackingData.patientIdentity.location))]),_c('p',{staticClass:"text-bold"},[_vm._v("Status Darah : "),_c('span',[_vm._v(_vm._s(_vm.bloodStatus))])]),_c('p',[_vm._v("Aftaper: "+_vm._s(_vm.bloodTrackingData.aftaper))]),_c('div',{staticClass:"text-center border rounded p-3 mt-3"},[_c('h2',[_vm._v("Pengolahan")]),(
          _vm.bloodTrackingData.blood_processing ||
            _vm.bloodTrackingData.componentResults.length > 0
        )?_c('div',[_c('table',{staticClass:"table table-sm"},[_vm._m(0),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.blood_processing.sieving_process))]),_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.blood_processing.weighing_process))]),_c('td',[_vm._v(" "+_vm._s(_vm.bloodTrackingData.blood_processing.centrifugation_process)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.bloodTrackingData.blood_processing.separation_process)+" ")])])]),_c('h4',[_vm._v("Hasil Pemisahan")]),_c('table',{staticClass:"table table-sm"},[_vm._m(1),_vm._l((_vm.bloodTrackingData.componentResults),function(component,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(component.component_barcode))]),_c('td',[_vm._v(_vm._s(component.volume))]),_c('td',[_vm._v(_vm._s(component.weight))]),_c('td',[_c('b-badge',{attrs:{"variant":component.status === 'success' ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(component.status === "success" ? "Berhasil" : "Gagal")+" ")])],1)])})],2)]):_c('div',{staticClass:"alert alert-warning"},[_vm._v(" Data Pengolahan Belum Tersedia ")])]),[_c('div',{staticClass:"text-center border rounded p-3 mt-3"},[_c('h2',[_vm._v("KGD")]),(_vm.bloodTrackingData.kgd)?_c('div',[_c('table',{staticClass:"table table-sm"},[_vm._m(2),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.kgd.created_at))])])]),_c('h4',[_vm._v("Hasil Pengecekkan")]),_c('table',{staticClass:"table table-sm"},[_vm._m(3),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.kgd.initial_blood))]),_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.kgd.result_blood))])])])]):_c('div',{staticClass:"alert alert-warning"},[_vm._v(" Data KGD belum tersedia. ")])]),_c('div',{staticClass:"text-center border rounded p-3 mt-3"},[_c('h2',[_vm._v("Hasil Antibody")]),(_vm.bloodTrackingData.antibody)?_c('div',[_c('b-badge',{attrs:{"size":"lg","variant":_vm.bloodTrackingData.antibody.status === 'negative'
                ? 'primary'
                : 'danger'}},[_vm._v(" "+_vm._s(_vm.bloodTrackingData.antibody.status === "negative" ? "Negatif" : _vm.bloodTrackingData.antibody.status)+" ")])],1):_c('div',{staticClass:"alert alert-warning"},[_vm._v(" Data antibody belum tersedia. ")])]),_c('div',{staticClass:"text-center border rounded p-3 mt-3"},[_c('h2',[_vm._v("HASIL IMLTD")]),(_vm.bloodTrackingData.screening)?_c('div',[_c('table',{staticClass:"table table-sm"},[_vm._m(4),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.screening.created_at))])])]),_c('b-badge',{staticClass:"mb-3",attrs:{"size":"lg","variant":_vm.bloodTrackingData.screening.status === 'non-reaktif'
                ? 'success' : _vm.bloodTrackingData.screening.status === 'reaktif' ? 'danger' :
                  _vm.bloodTrackingData.screening.status === 'greyzone' ? 'light' : ''}},[_vm._v(" "+_vm._s(_vm.bloodTrackingData.screening.status === "non-reaktif" ? "Non-Reaktif" : _vm.bloodTrackingData.screening.status === "reaktif" ? "Reaktif" : _vm.bloodTrackingData.screening.status === "greyzone" ? 'Grey Zone' : '')+" ")]),_c('table',{staticClass:"table table-sm"},[_vm._m(5),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.screening.syphilis ?? "Neg"))]),_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.screening.hepatitis_b ?? "Neg"))]),_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.screening.hepatitis_c ?? "Neg"))]),_c('td',[_vm._v(_vm._s(_vm.bloodTrackingData.screening.hiv_aids ?? "Neg"))])])])],1):_c('div',{staticClass:"alert alert-warning"},[_vm._v(" Data screening belum tersedia. ")])])]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Penyileran")]),_c('th',[_vm._v("Penimbangan")]),_c('th',[_vm._v("Pemutaran")]),_c('th',[_vm._v("Pemisahan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Barcode Hasil Olah")]),_c('th',[_vm._v("Volume")]),_c('th',[_vm._v("Berat")]),_c('th',[_vm._v("Status")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Tgl. Pengecekkan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Gol. Darah Sebelum")]),_c('th',[_vm._v("Gol. Darah Hasil Konfirmasi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Tgl. Pengecekkan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',[_vm._v("Syphillis")]),_c('th',[_vm._v("Hepatitis B")]),_c('th',[_vm._v("Hepatitis C")]),_c('th',[_vm._v("HIV")])])
}]

export { render, staticRenderFns }