<template>
  <div>
    <section class="bg-white p-3 rounded shadow-sm">
      <h1>Pelacakan Darah</h1>

      <b-form-group
        class="mt-5 w-100"
        label="Masukkan Barcode"
        label-for="barcode-input"
      >
        <b-form-input
          id="barcode-input"
          v-model="formData.barcode"
          type="text"
          placeholder="Masukkan barcode"
          @input="checkingBarcodes"
        />
      </b-form-group>
      <b-row class="d-flex justify-content-center">
        <b-col cols="auto">
          <div class="table-wrapper w-100 mx-auto overflow-scroll">
            <b-table
              class="table-borderless table-responsive w-100"
              :fields="table.fields"
              :items="table.items"
              :busy="table.isBusy"
              show-empty
            >
              <template #table-busy>
                <div class="hp-p1-body text-center my-2">
                  <b-spinner class="align-middle mr-8" />
                  <strong>Memuat...</strong>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </section>

    {{ errorMessages }}
    <section class="bg-white p-3 rounded shadow-sm mt-5">
      <p>Nama Pendonor: {{ bloodTrackingData.patientIdentity.name }}</p>
      <p>Nama MU: {{ bloodTrackingData.patientIdentity.location }}</p>
      <p class="text-bold">Status Darah :  <span>{{ bloodStatus }}</span></p>

      <p>Aftaper: {{ bloodTrackingData.aftaper }}</p>
      <div class="text-center border rounded p-3 mt-3">
        <h2>Pengolahan</h2>
        <div
          v-if="
            bloodTrackingData.blood_processing ||
              bloodTrackingData.componentResults.length > 0
          "
        >
          <table class="table table-sm">
            <tr>
              <th>Penyileran</th>
              <th>Penimbangan</th>
              <th>Pemutaran</th>
              <th>Pemisahan</th>
            </tr>
            <tr>
              <td>{{ bloodTrackingData.blood_processing.sieving_process }}</td>
              <td>{{ bloodTrackingData.blood_processing.weighing_process }}</td>
              <td>
                {{ bloodTrackingData.blood_processing.centrifugation_process }}
              </td>
              <td>
                {{ bloodTrackingData.blood_processing.separation_process }}
              </td>
            </tr>
          </table>
          <h4>Hasil Pemisahan</h4>
          <table class="table table-sm">
            <tr>
              <th>Barcode Hasil Olah</th>
              <th>Volume</th>
              <th>Berat</th>
              <th>Status</th>
            </tr>
            <tr
              v-for="(component, index) in bloodTrackingData.componentResults"
              :key="index"
            >
              <td>{{ component.component_barcode }}</td>
              <td>{{ component.volume }}</td>
              <td>{{ component.weight }}</td>
              <td>
                <b-badge
                  :variant="
                    component.status === 'success' ? 'success' : 'danger'
                  "
                >
                  {{ component.status === "success" ? "Berhasil" : "Gagal" }}
                </b-badge>
              </td>
            </tr>
          </table>
        </div>
        <div
          v-else
          class="alert alert-warning"
        >
          Data Pengolahan Belum Tersedia
        </div>
      </div>

      <template>
        <!-- KGD LIST CHECKING -->
        <div class="text-center border rounded p-3 mt-3">
          <h2>KGD</h2>
          <div v-if="bloodTrackingData.kgd">
            <table class="table table-sm">
              <tr>
                <th>Tgl. Pengecekkan</th>
              </tr>
              <tr>
                <td>{{ bloodTrackingData.kgd.created_at }}</td>
              </tr>
            </table>
            <h4>Hasil Pengecekkan</h4>
            <table class="table table-sm">
              <tr>
                <th>Gol. Darah Sebelum</th>
                <th>Gol. Darah Hasil Konfirmasi</th>
              </tr>
              <tr>
                <td>{{ bloodTrackingData.kgd.initial_blood }}</td>
                <td>{{ bloodTrackingData.kgd.result_blood }}</td>
              </tr>
            </table>
          </div>
          <div
            v-else
            class="alert alert-warning"
          >
            Data KGD belum tersedia.
          </div>
        </div>

        <!-- Antibody Result -->
        <div class="text-center border rounded p-3 mt-3">
          <h2>Hasil Antibody</h2>
          <div v-if="bloodTrackingData.antibody">
            <b-badge
              size="lg"
              :variant="
                bloodTrackingData.antibody.status === 'negative'
                  ? 'primary'
                  : 'danger'
              "
            >
              {{
                bloodTrackingData.antibody.status === "negative"
                  ? "Negatif"
                  : bloodTrackingData.antibody.status
              }}
            </b-badge>
          </div>
          <div
            v-else
            class="alert alert-warning"
          >
            Data antibody belum tersedia.
          </div>
        </div>

        <!-- IMLTD TABLE -->
        <div class="text-center border rounded p-3 mt-3">
          <h2>HASIL IMLTD</h2>
          <div v-if="bloodTrackingData.screening">
            <table class="table table-sm">
              <tr>
                <th>Tgl. Pengecekkan</th>
              </tr>
              <tr>
                <td>{{ bloodTrackingData.screening.created_at }}</td>
              </tr>
            </table>

            <b-badge
              class="mb-3"
              size="lg"
              :variant="
                bloodTrackingData.screening.status === 'non-reaktif'
                  ? 'success' : bloodTrackingData.screening.status === 'reaktif' ? 'danger' :
                    bloodTrackingData.screening.status === 'greyzone' ? 'light' : ''
              "
            >
              {{
                bloodTrackingData.screening.status === "non-reaktif"
                  ? "Non-Reaktif" : bloodTrackingData.screening.status === "reaktif" ? "Reaktif" : bloodTrackingData.screening.status === "greyzone" ? 'Grey Zone' : ''
              }}
            </b-badge>
            <table class="table table-sm">
              <tr>
                <th>Syphillis</th>
                <th>Hepatitis B</th>
                <th>Hepatitis C</th>
                <th>HIV</th>
              </tr>
              <tr>
                <td>{{ bloodTrackingData.screening.syphilis ?? "Neg" }}</td>
                <td>{{ bloodTrackingData.screening.hepatitis_b ?? "Neg" }}</td>
                <td>{{ bloodTrackingData.screening.hepatitis_c ?? "Neg" }}</td>
                <td>{{ bloodTrackingData.screening.hiv_aids ?? "Neg" }}</td>
              </tr>
            </table>
          </div>
          <div
            v-else
            class="alert alert-warning"
          >
            Data screening belum tersedia.
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BTable, BBadge, BSpinner,
} from 'bootstrap-vue'
import manageBloodProcessAPI from '@/api/blood_process/manageBloodProcessAPI'
import { debounce } from 'lodash'
import mapBloodType from '../../../../utils/mapBloodType'

export default {
  name: 'BloodTracking',
  components: {
    BFormInput,
    BFormGroup,
    BTable,
    BBadge,
    BSpinner,
    BSpinner,
  },
  data() {
    return {
      // data binding input barcode
      formData: {
        barcode: '',
        lastValidBarcode: '',
      },
      debounceTimer: null,
      isProcessing: false,
      debounceTimer: null,
      isProcessing: false,
      // state loading untuk komponen
      apiCallMade: false,
      apiCallMade: false,
      isLoading: false,
      isBusy: false,
      // state untuk mengecek ketersediaan barcode
      isBarcodeFound: false,
      // jeda dalam mengambil data barcode
      debounceCheckedBarcode: null,
      // untuk menampilkan data kosong
      errorMessages: '',
      // menampung data hasil blood tracking
      bloodTrackingData: {
        barcodeInformation: {
          barcode: '',
          blood_bag_number: '',
          blood_type: '',
          bag_type: '',
        },
        patientIdentity: {
          name: '',
          address: '',
          phone: '',
          donor_date: '',
          location: '',
          donor_times: null,
        },
        screening: null,
        kgd: null,
        antibody: null,
        blood_processing: null,
        componentResults: [],
      },
      table: {
        fields: [
          { key: 'no', label: 'No' },
          { key: 'barcode', label: 'Barcode' },
          { key: 'blood_bag_number', label: 'Bag No' },
          { key: 'blood_type', label: 'Gol. Darah' },
          { key: 'bag_type', label: 'Jenis' },
        ],
        items: [],
        isBusy: false,
      },
    }
  },
  computed: {
    bloodStatus() {
      if (!this.apiCallMade) {
        return '-'
      }

      const { screening, antibody, kgd } = this.bloodTrackingData

      if (!screening || !kgd || !antibody) {
        return 'Darah Karantina'
      }

      if (screening.status === 'non-reaktif') {
        if (!antibody) {
          return 'Darah Karantina'
        }
        return antibody.status === 'negative' ? 'Darah Sehat' : 'Darah Reaktif'
      }

      if (['reaktif', 'greyzone'].includes(screening.status) || screening.status.includes('positif')) {
        return 'Darah Reaktif'
      }

      return 'Status tidak diketahui'
    },
  },
  watch: {
    'formData.barcodes': function (newValue) {
      this.debounceCheckedBarcode()
    },
  },
  created() {
    this.debounceCheckedBarcode = debounce(this.checkingBarcodes, 300)
  },
  methods: {
    async checkingBarcodes() {
      try {
        if (!this.formData.barcode.trim()) {
          this.isBusy = false
          this.table.isBusy = false
          // Reset data
          this.resetData()
          return
        }

        this.table.isBusy = true

        this.isBusy = true
        this.errorMessage = ''

        const response = await manageBloodProcessAPI.trackingBloodProcess(
          this.formData.barcode,
        )

        if (response && response.data.success) {
          const { data } = response.data

          // Map response data for Barcode Informations
          this.bloodTrackingData.barcodeInformation = {
            no: 1,
            barcode: data.barcode,
            blood_bag_number: data.blood_bag_number,
            bag_type: data.bag_type,
            blood_type: mapBloodType(data.blood_type),
          }

          this.table.items = [this.bloodTrackingData.barcodeInformation]

          // Handle registered_user data
          this.bloodTrackingData.patientIdentity = data.registered_user
            ? {
              name: data.registered_user.name,
              address: data.registered_user.address,
              phone: data.registered_user.phone,
              donor_date: data.registered_user.donor_date,
              location: data.registered_user.location,
              donor_times: data.registered_user.donor_times,
            }
            : null

          // Handle component_results data
          this.bloodTrackingData.componentResults = data.component_results || []

          // Handle blood_processing data
          this.bloodTrackingData.blood_processing = data.blood_processing || null

          // Handle screening data
          this.bloodTrackingData.screening = data.screening || null

          // Handle kgd data
          this.bloodTrackingData.kgd = data.kgd || null

          // Handle screening_antibodi data
          this.bloodTrackingData.antibody = data.screening_antibodi || null

          this.apiCallMade = true

          this.table.isBusy = false
        } else {
          this.errorMessage = 'Data tidak ditemukan'
          this.apiCallMade = false
        }
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: 'Terjadi Kesalahan',
          variant: 'danger',
          solid: true,
        })

        this.table.items = []

        this.bloodTrackingData = {
          barcodeInformation: {
            barcode: '',
            blood_bag_number: '',
            blood_type: '',
            bag_type: '',
          },
          patientIdentity: {
            name: '',
            address: '',
            phone: '',
            donor_date: '',
            location: '',
            donor_times: null,
          },
          screening: null,
          kgd: null,
          antibody: null,
          blood_processing: null,
          componentResults: [],
        }

        this.table.isBusy = false
      } finally {
        this.isBusy = false
      }
    },

    resetData() {
      this.bloodTrackingData = {
        barcodeInformation: {
          barcode: '',
          blood_bag_number: '',
          blood_type: '',
          bag_type: '',
        },
        patientIdentity: {
          name: '',
          address: '',
          phone: '',
          donor_date: '',
          location: '',
          donor_times: null,
        },
        screening: null,
        kgd: null,
        antibody: null,
        blood_processing: null,
        componentResults: [],
      }
      this.table.items = []
      this.errorMessage = ''
      this.formData.barcode = ''
      this.apiCallMade = false
    },
  },
}
</script>

<style scoped>
.table-wrapper {
  max-height: 600px;
}

.overflow-scroll {
  overflow-y: auto;
}
</style>
